












import {
  Component,
  Vue,
} from 'vue-property-decorator';
import {
  getComponent, getConfigEnv
} from '@/utils/helpers';




@Component({
  components: {
        UserSettings: () => getComponent('common/UserSettings'),
  },
})
export default class UserReport extends Vue {

    get operatorName(){
        return getConfigEnv('OPERATOR_LOWERCASED')
    }

mounted(){
    setTimeout(() => {
        let doc: any = document.getElementById('frame')
        console.log(doc)
        let subDoc = doc?.querySelector('.displayAreaViewport')
        console.log(subDoc)
        var css = '<style type="text/css">' +
          '.displayAreaViewport{ background: #283851}; ' +
          '</style>';

          doc.document.head.appendChild(css);
          
    }, 8000)
}
}


